<script>
  import { computed } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import firebase from 'firebase/compat/app';
  import { useStore } from 'vuex';
  //import Swal from 'sweetalert2';
  import { db, auth, storage } from './main.js';
  import { doc, getDoc } from "firebase/firestore";
  import Swal from 'sweetalert2';
  import actualizarDocumento from './scripts/actualizarDocumento';
  import { signOut } from 'firebase/auth';
  
  export default{    
    setup(){            
      const router = useRouter();
      const route = useRoute();
      const store = useStore();

      //const permisosUsuarioDefecto = store.getters.obtenerPermisosUsuarioDefecto;
      //console.log(permisosUsuarioDefecto);

      //store.dispatch('actualizarUsuarioLogueado', {email: "ninguno", urlImagen: "ninguna", permisos : permisosUsuarioDefecto})

      firebase.auth().onAuthStateChanged((user)=>{
        if(!user){
          console.log("App.vue = no hay usuario logueado");
          const docRef = doc(db, "configuraciones", "permisosUsuario");
          getDoc(docRef).then((doc)=>{
            if(doc.exists() == true){
              store.dispatch('actualizarUsuarioLogueado', {email:"ninguno", urlImagen: "ninguna", permisos : doc.data()})
              console.log(doc.data())
            }else{
              Swal.fire('No se encuentra el documento "permisosUsuario" en el')
            }            
          }).catch((err)=>{
            console.log(err)
          });        
          router.replace('/login');
          //store.dispatch('actualizarUsuarioLogueado', {email:'ninguno', permisos:permisosUsuarioDefecto, urlImagen:"ninguna"})
        }else {
            //verifico los permisos que tenga, los comparo con los por defecto
            console.log("App.vue => Si hay usuario logueado, =>"+user.email);

            let docRef2 = db.collection("usuarios").doc(user.email);
            getDoc(docRef2).then((doc)=>{
              if(doc.exists() == true){
                let validacionActualizarPermisosUsuario = false;
                
                let permisosActualesUsuario = doc.data().permisos;
                //console.log("permisos en firestore para el usuario: "+user.email, permisosActualesUsuario)

                //Consulto los permisos por Defecto del sistema
                let docRef = db.collection("configuraciones").doc("permisosUsuario");
                docRef.get().then((doc2) => {
                  if(doc2.exists){
                    //comparo que los permisos por defecto (en firestore) sean los mismo que tiene asignados el usuario (en firestore)
                    let permisosDefecto = doc2.data();
                    for (let clavePermisoDefecto in permisosDefecto){
                      //console.log("existencia en permisos actuales, permiso: "+clavePermisoDefecto+"=>", clavePermisoDefecto in permisosActualesUsuario);
                      if((clavePermisoDefecto in permisosActualesUsuario) == false){
                        //Aqui es que no tiene esa clave			
                        //console.log("clave "+clavePermisoDefecto+" NO existe en ambos permisos")					
                        permisosActualesUsuario[clavePermisoDefecto] = permisosDefecto[clavePermisoDefecto]
                        validacionActualizarPermisosUsuario = true;
                      }else{
                        //console.log("clave "+clavePermisoDefecto+" SI existe en ambos permisos")
                        //Aqui si la tiene pero falta verificar que sea identica
                        //console.log(permisosDefecto[clavePermisoDefecto] == permisosActualesUsuario[clavePermisoDefecto])
                      }
                    }
                    if(validacionActualizarPermisosUsuario == true){
                      //le actualizo los permisos al usuario
                      actualizarDocumento.methods['unCampo']('usuarios', user.email, "permisos", permisosActualesUsuario).then(()=>{console.log("permisos del usuario estaban incompletos, se le actualizaron")});
                    }
                    //traigo la imagen de perfil
                    const ref = storage.ref();
                    //traigo la imagen del usuario						                
                    ref.child('usuarios/'+user.email+'.png').getDownloadURL().then((url)=>{              
                      //console.log("URL del usuario que SI tiene imagen", urlImagen);
                      store.dispatch('actualizarUsuarioLogueado', { email:user.email, urlImagen : url, permisos : permisosActualesUsuario });
                    }).catch((err)=>{
                      if(err.code == "storage/object-not-found"){
                        console.log("el usuario no tiene imagen asignada");
                        ref.child('usuarios/sinImagen.png').getDownloadURL().then((url)=>{
                          //console.log("URL del usuario que NO tiene imagen", urlImagen);
                          store.dispatch('actualizarUsuarioLogueado', {
                            email:user.email,
                            urlImagen: url,
                            permisos : permisosActualesUsuario
                          })
                        })
                      }
                    });

                    store.dispatch('consultarClientes');
                    store.dispatch('consultarGruposClientes');
                    store.dispatch('consultarPuntosVenta');
                    store.dispatch('consultarProductos');
                    store.dispatch('consultarColeccion','grupos');
                    //aqui lo que hago es traerme todos los grupos de punto de venta que estan en un solo doc en la coleccion datosDefecto en el doc puntosDeVenta, esto para ahorrar lecturas en firestore
                    store.dispatch('consultarArrayObjsDeDoc',{coleccion:"datosDefecto", idDoc:"gruposPuntosDeVenta", estado:"gruposPuntosDeVenta"});
                    
                    
                    router.push('/');
                  }else{
                    Swal.fire('No se encuentra el documento "permisosUsuario" la coleccion "configuraciones"');
                  }            
                }).catch((err)=>{
                  console.log(err)
                });

              }else{
                Swal.fire('El usuario '+user.email+' no tiene permisos creados en la coleccion "usuarios"')
              }            
            }).catch((err)=>{
              console.log(err)
            });

            //console.log("usuarioLogueadoEnVUEX",store.getters.obtenerUsuarioLogueado)
            if(route.path == "/login"){
            router.replace('/');
          }
        } 
      });   

      //let permisosUsuarioDefecto = store.getters.obtenerPermisosUsuarioDefecto;

      const cerrarSesion = async () =>{
        console.clear();
        await signOut(auth);
      }

      return{
        cerrarSesion,
        usuarioLogueado: computed(() => store.state.user),
        permisosUsuario: computed(() => store.state.user.permisos)
      }
    }  
  }
</script>

<template >
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
  <meta http-equiv="x-ua-compatible" content="ie=edge"/>
  <div class="header pt-0">
    <img v-if="usuarioLogueado.email=='ninguno'" src="./assets/logo.png" class="logo img-fluid"/>
    <br/>
    <hr v-if="usuarioLogueado.email=='ninguno'"/>
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <h2 v-if="usuarioLogueado.email=='ninguno'" style="font-weight: bold;">ADMON</h2>
        </div>
      </div>
    </div>    
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <h2 v-if="usuarioLogueado.email!='ninguno'" style="font-weight: bold;">ADMON</h2>
        </div>
        <div class="col">
          <h3 v-if="usuarioLogueado.email!='ninguno'">{{usuarioLogueado.email}}</h3>
        </div>
        <div class="col">
          <img v-if="usuarioLogueado.email!='ninguno'" :src="usuarioLogueado.urlImagen" alt="Avatar" class="avatar"/>
        </div>
      </div>
    </div>
    <!--{{permisosUsuario}}-->
  </div>  
  <nav v-if="!$route.meta.hideNavbar" class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <img src="./assets/logo.png" class="logo img-fluid" height="200" width="140"/>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <!--v-if="permisosUsuario.clientes.global"-->    
          <li v-if="permisosUsuario.clientes.global" class="nav-item dropdown">            
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="material-icons md-24">account_circle</span>Clientes
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!--v-if="permisosUsuario.clientes.crear"-->
              <li><router-link v-if="permisosUsuario.clientes.crear" class="dropdown-item" to="/crearCliente">Crear</router-link></li>
              <!--v-if="permisosUsuario.clientes.consultar"-->
              <li><router-link v-if="permisosUsuario.clientes.consultar" class="dropdown-item" to="/consultarClientes">Consultar</router-link></li>
            </ul>
          </li>
          <!--v-if="permisosUsuario.ventas.global"-->
          <li v-if="permisosUsuario.ventas.global" class="nav-item dropdown">            
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="material-icons md-24">shopping_cart_checkout</span>Ventas
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><router-link v-if="permisosUsuario.ventas.crear" class="dropdown-item" to="/crearVenta">Registrar</router-link></li>
            </ul>
          </li>
          <!--v-if="permisosUsuario.mercadeo.global"--> 
          <li v-if="permisosUsuario.mercadeo.global" class="nav-item dropdown">            
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="material-icons md-24">wysiwyg</span>Mercadeo
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><router-link v-if="permisosUsuario.mercadeo.registrarVisitaPuntoDeVenta.crear" class="dropdown-item" to="/registrarProductosPtoVenta" >Registrar visita punto de Venta</router-link></li>
              <li><router-link v-if="permisosUsuario.mercadeo.reporteProductosMasVendidos.consultar" class="dropdown-item" to="/reporteProductosMasVendidos" >PDF Productos mas vendidos en general</router-link></li>
              <li><router-link v-if="permisosUsuario.mercadeo.graficaVentasPorCliente.consultar" class="dropdown-item" to="/graficaVentasPorCliente" >Grafica productos por cliente</router-link></li>
              <li><router-link v-if="permisosUsuario.mercadeo.graficaPorcProductosVisitaPtoVenta.consultar" class="dropdown-item" to="/graficaPorcProductosVisitaPtoVenta" >Grafica porcentaje productos en visita pto venta</router-link></li>
              <li><router-link v-if="permisosUsuario.mercadeo.graficaVentasPorGrupoClientes.consultar" class="dropdown-item" to="/graficaVentasPorGrupoClientes" >Grafica productos por grupo clientes</router-link></li>          
              <li><router-link v-if="permisosUsuario.mercadeo.reporteParticipacionClientesEnGrupo.consultar" class="dropdown-item" to="/reporteParticipacionClientesEnGrupo" >Reporte participacion de clientes en grupo</router-link></li>
              <li><router-link v-if="permisosUsuario.mercadeo.movimientosProductosEntreVisitasPuntosDeVenta.consultar" class="dropdown-item" to="/movimientosProductosEntreVisitasPuntosDeVenta" >Movimiento de productos entre visitas</router-link></li>
              <li><router-link v-if="permisosUsuario.mercadeo.consultarVisitasPuntosDeVenta.consultar" class="dropdown-item" to="/consultarVisitasPuntosDeVenta" >Consultar visitas punto de venta</router-link></li> 
              <li><router-link v-if="permisosUsuario.mercadeo.reporteExcelHistoricoVisitasPorGrupoPtoVenta.consultar" class="dropdown-item" to="/reporteExcelHistoricoVisitasPorGrupoPtoVenta" >Excel historial visitas de grupo pto venta</router-link></li>
            </ul>
          </li>
          <!--v-if="permisosUsuario.productos.global"-->
          <li v-if="permisosUsuario.productos.global" class="nav-item dropdown">         
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span  class="material-icons md-24">view_in_ar</span>Productos
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!--v-if="permisosUsuario.productos.crear"-->
              <li><router-link v-if="permisosUsuario.productos.crear" class="dropdown-item" to="/crearProducto" >Crear</router-link></li>
              <!--v-if="permisosUsuario.productos.consultar"-->
              <li><router-link v-if="permisosUsuario.productos.consultar" class="dropdown-item" to="/consultarProductos" >Consultar</router-link></li>
            </ul>
          </li>
          <!-- v-if="permisosUsuario.materiasPrimas.global"-->
          <li v-if="permisosUsuario.materiasPrimas.global" class="nav-item dropdown">             
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="material-icons md-24">shopping_basket</span>Mat prima
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><router-link v-if="permisosUsuario.materiasPrimas.crear" class="dropdown-item" to="/crearMateriaPrima" >Crear</router-link></li>
              <li><router-link v-if="permisosUsuario.materiasPrimas.global" class="dropdown-item" to="/consultarMateriasPrimas" >Consultar/Eliminar</router-link></li>
            </ul>
          </li>
          <!-- v-if="permisosUsuario.etiquetas.global"-->
          <li v-if="permisosUsuario.etiquetas.global" class="nav-item dropdown">                        
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="material-icons md-24">featured_play_list</span>Etiquetas
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><router-link v-if="permisosUsuario.etiquetas.crear" class="dropdown-item" to="/crearEtiquetas">Crear</router-link></li>
              <li><router-link v-if="permisosUsuario.etiquetas.consultar" class="dropdown-item" to="/consultarEtiquetas">Consultar/Eliminar</router-link></li>
            </ul>
          </li>
          <!-- v-if="permisosUsuario.datos.global"-->
          <li v-if="permisosUsuario.datos.global" class="nav-item dropdown">                        
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="material-icons md-24">featured_play_list</span>Datos
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!--v-if="permisosUsuario.datos.gruposClientes.crear"-->
              
              <li><router-link v-if="permisosUsuario.datos.gruposClientes.crear" class="dropdown-item" to="/crearGrupoClientes">Crear grupo cliente</router-link></li>              
              <li><router-link v-if="permisosUsuario.datos.puntosDeVenta.crear" class="dropdown-item" to="/crearPuntoDeVenta">Crear punto de venta</router-link></li> 
              <li><router-link v-if="permisosUsuario.datos.gruposPuntosDeVenta.crear" class="dropdown-item" to="/crearGrupoPuntosDeVenta">Crear grupo puntos de venta</router-link></li>
            </ul>            
          </li>
          <li class="nav-item">             
            <button class="nav-link btn btn-outline-danger btn-sm" @click="cerrarSesion" aria-current="page">
              <span class="material-icons md-24">logout</span>
              Cerrar Sesion
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view/>
</template>

<style lang="scss">

  /* fallback */
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v139/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
    vertical-align: -6px;
    //border: solid 1px #ccc;
    //margin: 0px 0px 0px 0px;
  }

  .avatar {
    vertical-align: middle;
    width: 60px;
    height: 60px;
    border-radius: 60%;
  }


  .header {
    overflow: hidden;
    padding: 20px 20px;
    background: #ffed00;
    overflow: hidden;
    box-shadow: 0 8px 10px rgba(0,0,0,0.5);
    padding-bottom: 10px;
    /*text-align: center;*/
  }
  body{
    background: #ffffff;
    color: #2c3e50;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }
  a {
    color:inherit;
  }  
</style>

