import { db } from '../main.js';
import { doc, updateDoc  } from "firebase/firestore";

export default {
    name: 'actualizarDocumento',
    methods:{
        unCampo: async function(coleccion, id, campo, datosCampo)
        {
            console.log("campo a actualizar => "+campo);
            console.log("datos del campo => ",datosCampo)
            const docRef = doc(db, coleccion, id);
            let objDatos = {};
            objDatos[campo] = datosCampo;
            await updateDoc(docRef, objDatos);
        }
    }
 }