import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: () => import('../views/inicio.vue'),
    meta:{
      title:"Admon - Inicio"
    }
  },
  {
    path:'/login',
    name:'login',
    component:() => import('../views/login.vue'),
    meta: {
      hideNavbar: true,
      title:"Admon - login"
     }
  },
  {
    path:'/consultarProductos',
    name:'consultarProductos',
    component:() => import('../views/consultarProductos.vue'),
    meta:{
      title:"Admon - consultar productos"
    }
  },
  {
    path:'/reporteProductosMasVendidos',
    name:'reporteProductosMasVendidos',
    component:() => import('../views/reporteProductosMasVendidos.vue'),
    meta:{
      title:"Admon - reporte productos mas vendidos"
    }
  },  
  {
    path:'/graficaVentasPorCliente',
    name:'graficaVentasPorCliente',
    component:() => import('../views/graficaVentasPorCliente.vue'),
    meta:{
      title:"Admon - grafica ventas por cliente"
    }
  }, 
  {
    path:'/crearVenta',
    name:'crearVenta',
    component:() => import('../views/crearVenta.vue'),
    meta:{
      title:"Admon - crear venta"
    }
  },
  {
    path:'/crearMateriaPrima',
    name:'crearMateriaPrima',
    component:() => import('../views/crearMateriaPrima.vue'),
    meta:{
      title:"Admon - crear materia prima"
    }
  },
  {
    path:'/crearCliente',
    name:'crearCliente',
    component:() => import('../views/crearCliente.vue'),
    meta:{
      title:"Admon - crear cliente"
    }
  },
  {
    path:'/crearPuntoDeVenta',
    name:'crearPuntoDeVenta',
    component:() => import('../views/crearPuntoDeVenta.vue'),
    meta:{
      title:"Admon - crear punto de venta"
    }
  },
  {
    path:'/crearProducto',
    name:'crearProducto',
    component:() => import('../views/crearProducto.vue'),
    meta:{
      title:"Admon - Crear producto"
    }
  },
  {
    path: '/crearEtiquetas',
    name: 'Etiquetas',
    component: () => import('../views/crearEtiquetas.vue'),
    meta:{
      title:"Admon - Etiquetas"
    }
  },
  {
    path:'/consultarClientes',
    name:'consultarClientes',
    component:() => import('../views/consultarClientes.vue'),
    meta:{
      title:"Admon - consultar Clientes"
    }
  },
  {
    path:'/consultarMateriasPrimas',
    name:'consultarMateriasPrimas',
    component:() => import('../views/consultarMateriasPrimas.vue'),
    meta:{
      title:"Admon - consultar Clientes"
    }
  },
  {
    path:'/consultarEtiquetas',
    name:'consultarEtiquetas',
    component:() => import('../views/consultarEtiquetas.vue'),
    meta:{
      title:"Admon - consultar etiquetas"
    }
  },
  {
    path:'/crearGrupoClientes',
    name:'crearGrupoClientes',
    component:() => import('../views/crearGrupoClientes.vue'),
    meta:{
      title:"Admon - crear grupo clientes"
    }
  },
  {
    path:'/graficaVentasPorGrupoClientes',
    name:'graficaVentasPorGrupoClientes',
    component:() => import('../views/graficaVentasPorGrupoClientes.vue'),
    meta:{
      title:"Admon - grafica ventas por grupo clientes"
    }
  },  {
    path:'/reporteParticipacionClientesEnGrupo',
    name:'reporteParticipacionClientesEnGrupo',
    component:() => import('../views/reporteParticipacionClientesEnGrupo.vue'),
    meta:{
      title:"Admon - reporte participacion clientes en grupo"
    }
  },{
    path:'/registrarProductosPtoVenta',
    name:'registrarProductosPtoVenta',
    component:() => import('../views/registrarProductosPtoVenta.vue'),
    meta:{
      title:"Admon - registrar producto en pto venta"
    }
  },
  {
    path:'/movimientosProductosEntreVisitasPuntosDeVenta',
    name:'movimientosProductosEntreVisitasPuntosDeVenta',
    component:() => import('../views/movimientosProductosEntreVisitasPuntosDeVenta.vue'),
    meta:{
      title:"Admon - movimiento de productos en puntos de venta"
    }
  },
  {
    path:'/consultarVisitasPuntosDeVenta',
    name:'consultarVisitasPuntosDeVenta',
    component:() => import('../views/consultarVisitasPuntosDeVenta.vue'),
    meta:{
      title:"Admon - consultar visitas puntos de venta"
    }
  },
  {
    path:'/graficaPorcProductosVisitaPtoVenta',
    name:'graficaPorcProductosVisitaPtoVenta',
    component:() => import('../views/graficaPorcProductosVisitaPtoVenta.vue'),
    meta:{
      title:"Admon - grafica porcentaje productos en visita pto venta"
    }
  },
  {
    path:'/crearGrupoPuntosDeVenta',
    name:'crearGrupoPuntosDeVenta',
    component:() => import('../views/crearGrupoPuntosDeVenta.vue'),
    meta:{
      title:"Admon - crear grupo clientes"
    }
  },
  {
    path:'/reporteExcelHistoricoVisitasPorGrupoPtoVenta',
    name:'reporteExcelHistoricoVisitasPorGrupoPtoVenta',
    component:() => import('../views/reporteExcelHistoricoVisitasPorGrupoPtoVenta.vue'),
    meta:{
      title:"Admon - generar excel"
    }
  },

  

  
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) =>{
  document.title = `${to.meta.title}`;
  next();
})

export default router
