import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import { createApp } from 'vue'
//import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2';
import storeaAutenticacion from './store/index'
import VueChartkick from 'vue-chartkick'
import vSelect from 'vue-select';
import 'chartkick/chart.js'

import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'vue-select/dist/vue-select.css';

const firebaseConfig = {
  apiKey: "AIzaSyDfAa64FCuk0iS5B-smO2_PTFG2wFeVWPk",
  authDomain: "dulcesita---admon.firebaseapp.com",
  projectId: "dulcesita---admon",
  storageBucket: "dulcesita---admon.appspot.com",
  messagingSenderId: "744343616348",
  appId: "1:744343616348:web:6a9fe8937821ca55849f99",
  measurementId: "G-2PXV2G74KB"
};

// Use this to initialize the firebase App
firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export {  db, auth, storage, Swal };

const app = createApp(App);
app.component('v-select',vSelect);
app.use(router);
app.use(storeaAutenticacion);
app.use(VueChartkick);
app.mount('#app');


