import { createStore } from 'vuex'
//import { auth, storage } from '../main';
//import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
//import VuexPersistence from 'vuex-persist';
import { db } from '../main';
import { collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate';
import { Swal } from '../main.js';

const ls = new SecureLS({
    isCompression: true
})

const storeaAutenticacion = createStore({
    plugins: [createPersistedState({
        //variables que quiero que permanezcan incluso si se recarga la pagina
        paths: ["user",
            "permisosUsuarioPorDefecto",
            "ciudades",
            "clientes",
            "materiasPrimas",
            "vehiculos",
            "conductores",
            "etiquetas",
            "productos",
            "puntosDeVenta",
            "gastos",
            "grupos",
            "gruposPuntosDeVenta",
            "ventas",
            "productosMasVendidosPorCliente",
            "productosMasVendidosPorClienteConPorcentaje",
            "totalesProductosMasVendidosPorCliente",
            "productosMasVendidosPorGrupoClientes",
            "productosMasVendidosPorGrupoClientesConPorcentaje",
            "totalesProductosMasVendidosPorGrupoClientes",
            "grupoClientes",
            "grafica"
        ],
        overwrite: true,
        storage: {
            getItem: (key) => { return ls.get(key) },
            setItem: (key, value) => { ls.set(key, value) },
            removeItem: (key) => { ls.remove(key) }
        }
    })],
    state: {
        //The user state will initially be null. After login, this state will be updated
        user: { email: "ninguno", permisos: {}, urlImagen: "ninguna" },
        usuariosMercadeo: "ninguno",
        ciudades: "ninguna",
        permisosUsuarioPorDefecto: {},
        clientes: "ninguno",
        grupoClientes: "ninguno",
        materiasPrimas: "ninguna",
        vehiculos: "ninguno",
        conductores: "ninguno",
        gastos: "ninguno",
        etiquetas: "ninguna",
        productos: "ninguno",
        puntosDeVenta: "ninguno",
        ultimoDocumento: "ninguno",
        grupos: "ninguno",
        gruposPuntosDeVenta: "ninguno",
        grafica: "ninguna",
        productosMasVendidosPorCliente: "ninguno",
        productosMasVendidosPorClienteConPorcentaje: "ninguno",
        totalesProductosMasVendidosPorCliente: "ninguno",

        productosMasVendidosPorGrupoClientes: "ninguno",
        productosMasVendidosPorGrupoClientesConPorcentaje: "ninguno",
        totalesProductosMasVendidosPorGrupoClientes: "ninguno",

        participacionVentasDeClientesEnSuGrupo: "ninguno"

    },
    mutations: {
        limpiarEstado(state, parametros) { state[parametros['estado']] = parametros.valor },        
        setEstado(state, payload) {
        console.log("seteando el estado "+payload.estado+" con datos", payload.datos)
            state[payload.estado] = payload.datos 
        },
        setUser(state, payload) { state.user = payload },
        setCiudades(state, payload) { state.ciudades = payload },
        setClientes(state, payload) { state.clientes = payload },
        setGrupoClientes(state, payload) { state.grupoClientes = payload },
        setGruposPuntosDeVenta(state, payload) { state.gruposPuntosDeVenta = payload },
        setMateriasPrimas(state, payload) { state.materiasPrimas = payload },
        setVehiculos(state, payload) { state.vehiculos = payload },
        setConductores(state, payload) { state.conductores = payload },
        setGastos(state, payload) { state.gastos = payload },
        setEtiquetas(state, payload) { state.etiquetas = payload },
        setProductos(state, payload) { state.productos = payload },
        setPuntosDeVenta(state, payload) { state.puntosDeVenta = payload },
        setUltimoDocumento(state, payload) { state.ultimoDocumento = payload },

        setProductosMasVendidosPorCliente(state, payload) { state.productosMasVendidosPorCliente = payload },
        setProductosMasVendidosPorClienteConPorcentaje(state, payload) { state.productosMasVendidosPorClienteConPorcentaje = payload },
        setTotalesProductosMasVendidosPorCliente(state, payload) { state.totalesProductosMasVendidosPorCliente = payload },

        setProductosMasVendidosPorGrupoClientes(state, payload) { state.productosMasVendidosPorGrupoClientes = payload },
        setProductosMasVendidosPorGrupoClientesConPorcentaje(state, payload) { state.productosMasVendidosPorGrupoClientesConPorcentaje = payload },
        setTotalesProductosMasVendidosPorGrupoClientes(state, payload) { state.totalesProductosMasVendidosPorGrupoClientes = payload },

        setParticipacionVentasDeClientesEnSuGrupo(state, payload) { state.participacionVentasDeClientesEnSuGrupo = payload }
    },
    actions: {
        async actualizarUsuarioLogueado(context, datosUsuario) {
            //console.log("VUEX => datosUsuariosRecibidos en actualizarUsuarioLogueado", datosUsuario);
            context.commit('setUser', datosUsuario);
        },
        async consultarColeccion(context, coleccion) {
            //console.log("se consultará la coleccion: " + coleccion)

            let datosRta = [];

            const q = query(collection(db, coleccion));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                datosRta.push({ ...doc.data() })
            });
            //console.log(datosRta)
            context.commit('setEstado', { estado: coleccion, datos: datosRta });
        },
        actualizarEstado(context, datos) {
            console.log(datos)
            context.commit('setEstado', { estado: datos.estado, datos: datos.datos });
        },
        /*
        async logout(context){

            /*
            console.log("se ejecuto el logout")
            await signOut(auth);
            
            //Consulto los permisos por Defecto del sistema
            let docRef = db.collection("configuraciones").doc("permisosUsuario");
            docRef.get().then((doc) => {
            if(doc.exists){                
                let permisosDefecto = doc.data();
                context.commit('setUser', {email : "ninguno", urlImagen : "ninguna", permisos: permisosDefecto }) 
            }else{
                Swal.fire('No se encuentra el documento "permisosUsuario" la coleccion "configuraciones"');
            }            
            }).catch((err)=>{
                console.log(err)
            });

            //context.commit('setUser', {email:"ninguno", permisos : permisosUsuario, urlImagen:"ninguna"});
            context.commit('setClientes', [{id:0, nombre:"ninguno"}]);
            context.commit('setGrupoClientes', [{id:0, nombre:"ninguno"}]);
            context.commit('setPuntosDeVenta', [{id:0, nombre:"ninguno"}]);
            context.commit('setProductos', [{id:0, nombre:"ninguno"}]);
            context.commit('setEtiquetas', [{id:0, nombre:"ninguna"}]);

            
        },*/
        async consultarClientes(context) {
            let clientes = [];
            const q = query(collection(db, "clientes"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                clientes.push({ ...doc.data(), id: doc.id })
            });
            context.commit('setClientes', clientes);
        },
        async consultarGruposClientes(context) {
            const q = query(collection(db, "grupos"), where("tipoIntegrantes", "==", "clientes"));
            const querySnapshot = await getDocs(q);
            let grupoClientes = [];
            querySnapshot.forEach((doc) => { grupoClientes.push({ ...doc.data() }) });
            //console.log(grupoClientes);
            context.commit('setGrupoClientes', grupoClientes);
        },
        async consultarMateriasPrimas(context) {
            let materiasPrimas = [];
            const q = query(collection(db, "materiasPrimas"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                materiasPrimas.push({ ...doc.data(), id: doc.id })
            });
            //console.log("consultarMateriasPrimas en actions:")
            //console.log(materiasPrimas)
            context.commit('setMateriasPrimas', materiasPrimas);
        },
        async consultarEtiquetas(context) {
            let etiquetas = [];
            const q = query(collection(db, "etiquetas"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                etiquetas.push({ ...doc.data(), id: doc.id })
            });
            //console.log("etiquetas en actions:")
            //console.log(etiquetas);
            context.commit('setEtiquetas', etiquetas);
        },
        async productosMasVendidosPorCliente(context, parametros) {
            try {
                //console.log(parametros);
                context.commit('setProductosMasVendidosPorCliente', "ninguno");
                context.commit('setProductosMasVendidosPorClienteConPorcentaje', "ninguno")
                context.commit('setTotalesProductosMasVendidosPorCliente', 0);

                const q = query(collection(db, "ventas"),
                    where("fecha", ">=", parametros.fechaDesde),
                    where("fecha", "<=", parametros.fechaHasta),
                    where("cliente", "==", parametros.cliente));

                getDocs(q).then((snapshot) => {
                    if (snapshot.size == 0) {
                        //actualizo el estado por si esta una consulta guardada en el anteriormente
                        /*
                        context.commit('setProductosMasVendidosPorCliente', "ninguno");
                        context.commit('setProductosMasVendidosPorClienteConPorcentaje', "ninguno")
                        context.commit('setTotalesProductosMasVendidosPorCliente', 0);   
                        */
                        Swal.fire("No hay ventas en las fechas seleccionadas", "", "error");
                    }
                    else {
                        let totales = [];
                        let validacion = false;
                        let totalCajas = 0;
                        console.log(totalCajas);
                        let totalValor = 0;
                        console.log(totalValor)
                        console.log("numero de ventas: " + snapshot.size);
                        snapshot.docs.forEach((venta) => {
                            //console.log("venta:"+venta.data().id,venta.data().productos);  
                            venta.data().productos.forEach((productoIterado) => {
                                totalCajas += productoIterado.cantidad;
                                totalValor += productoIterado.cantidad * productoIterado.valor
                                //arranco en false para cada producto
                                validacion = false;
                                totales.forEach((productoEnTotales) => {
                                    if (productoIterado.nombre == productoEnTotales.nombre) {
                                        productoEnTotales.cantidad += productoIterado.cantidad
                                        productoEnTotales.valor += (productoIterado.cantidad * productoIterado.valor);
                                        //el producto si estaba en totales
                                        validacion = true;
                                    }
                                })
                                if (validacion == false) {
                                    //si el producto no estaba en totales entonces hay que agregarlo como viene
                                    totales.push({
                                        nombre: productoIterado.nombre,
                                        cantidad: productoIterado.cantidad,
                                        valor: (productoIterado.valor * productoIterado.cantidad)
                                    })
                                }
                            });
                        })

                        //console.log("totalCajas",totalCajas);

                        let objResultado = {};
                        //ahora que arme el array de objetos totales debo generar un objeto para que lo lea la grafica
                        //ya que esta lee objetos de la siguiente manera {"producto1":5, "producto2":4}
                        totales.forEach((p) => {
                            //console.log(p)
                            objResultado[p.nombre] = p.cantidad;
                            //totalCajas = objResultado[p.cantidad]                              
                        })

                        //totales es lo que enviaré a dibujar en la tabla, le saco el porcentaje
                        //de participacion a cada producto por valor o por cantidad dependiendo de lo escogido
                        let porcentaje;
                        if (parametros.ordenadoPor == "cantidad") {
                            totales.forEach((producto) => {
                                porcentaje = (producto.cantidad * 100) / totalCajas
                                producto["porcentaje"] = porcentaje.toFixed(1)
                            })
                        }
                        if (parametros.ordenadoPor == "valor") {
                            totales.forEach((producto) => {
                                porcentaje = (producto.valor * 100) / totalValor
                                producto["porcentaje"] = porcentaje.toFixed(1)
                            })
                        }

                        //ordeno totales de mayor a menor totales dependiendo si es por cajas o por valor
                        //recordar que totales se dibuja en la tabla
                        if (parametros.ordenadoPor == "cantidad") { totales.sort(((a, b) => b.cantidad - a.cantidad)); }
                        if (parametros.ordenadoPor == "valor") { totales.sort(((a, b) => b.valor - a.valor)); }

                        //se ordena el objeto de mayor a menor por la cantidad de cajas
                        //para que en la grafica quede ordenado, la grafica quedamos en siempre mostraba cantidades
                        const objOrdenadoPorCantidad = Object.entries(objResultado)
                            .sort(([, a], [, b]) => b - a)
                            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

                        //console.log("total valor",totalValor);

                        context.commit('setProductosMasVendidosPorCliente', objOrdenadoPorCantidad);
                        context.commit('setProductosMasVendidosPorClienteConPorcentaje', totales);
                        context.commit('setTotalesProductosMasVendidosPorCliente', { totalCajas: totalCajas, totalValor: totalValor });
                    }
                }).catch((err) => {
                    console.log(err.message);
                });
            }
            catch (e) {
                console.error("Error leyendo documentos: ", e);
            }
        },
        async productosMasVendidosPorGrupoClientes(context, parametros) {
            let ventas = [];
            try {
                context.commit('setProductosMasVendidosPorGrupoClientes', "ninguno");
                context.commit('setTotalesProductosMasVendidosPorGrupoClientes', "ninguno");
                context.commit('setProductosMasVendidosPorGrupoClientesConPorcentaje', "ninguno");

                //traigo los clientes que pertenecen a ese grupo
                let docRef = doc(db, "grupos", parametros.idGrupo);
                let docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    //integrantes es un array
                    let integrantes = docSnap.data().integrantes;

                    const citiesRef = collection(db, "ventas");
                    const q = query(citiesRef, where('cliente', 'in', integrantes),
                        where("fecha", ">=", parametros.fechaDesde),
                        where("fecha", "<=", parametros.fechaHasta),
                    );

                    getDocs(q).then((snapshot) => {
                        if (snapshot.size > 0) {
                            snapshot.docs.forEach((venta) => {
                                //console.log(venta.data());
                                ventas.push(venta.data());
                            })
                            //console.log(ventas)
                            //console.log(ventas.length);

                            let arrayProductosFinal = [];
                            let validacion;
                            let totalCajas = 0;
                            let totalValor = 0;

                            ventas.forEach((venta) => {
                                //console.log("venta");                                
                                //console.log("venta:"+venta.data().id,venta.data().productos);  
                                venta.productos.forEach((productoIterado) => {
                                    totalCajas += productoIterado.cantidad;
                                    totalValor += productoIterado.cantidad * productoIterado.valor
                                    //arranco en false para cada producto
                                    validacion = false;
                                    arrayProductosFinal.forEach((productoEnArrayProductosFinal) => {
                                        if (productoIterado.nombre == productoEnArrayProductosFinal.nombre) {
                                            productoEnArrayProductosFinal.cantidad += productoIterado.cantidad
                                            productoEnArrayProductosFinal.valor += (productoIterado.cantidad * productoIterado.valor);
                                            //el producto si estaba en totales
                                            validacion = true;
                                        }
                                    })
                                    if (validacion == false) {
                                        //si el producto no estaba en totales entonces hay que agregarlo como viene
                                        arrayProductosFinal.push({
                                            nombre: productoIterado.nombre,
                                            cantidad: productoIterado.cantidad,
                                            valor: (productoIterado.valor * productoIterado.cantidad)
                                        })
                                    }
                                });

                            })
                            //hasta aqui tenemos un array con los productos y sus totales de cantidad y valores
                            //console.log(arrayProductosFinal);

                            let objResultado = {};

                            //ahora que arme el array de objetos totales debo generar un objeto para que lo lea la grafica
                            //ya que esta lee objetos de la siguiente manera {"panela redonda":5, "panela cuadrada":4}
                            arrayProductosFinal.forEach((p) => {
                                //console.log(p)
                                objResultado[p.nombre] = p.cantidad;
                                //totalCajas = objResultado[p.cantidad]                              
                            })

                            //arrayProductosFinal es lo que enviaré a dibujar en la tabla, le saco el porcentaje
                            //de participacion a cada producto por valor o por cantidad dependiendo de lo escogido
                            let porcentaje;
                            if (parametros.ordenadoPor == "cantidad") {
                                //console.log("ordenados por cantidad")
                                arrayProductosFinal.forEach((producto) => {
                                    porcentaje = (producto.cantidad * 100) / totalCajas
                                    producto["porcentaje"] = porcentaje.toFixed(1)
                                })
                            }
                            if (parametros.ordenadoPor == "valor") {
                                //console.log("ordenados por valor")
                                arrayProductosFinal.forEach((producto) => {
                                    porcentaje = (producto.valor * 100) / totalValor
                                    producto["porcentaje"] = porcentaje.toFixed(1)
                                })
                            }

                            //ordeno totales de mayor a menor totales dependiendo si es por cajas o por valor
                            //recordar que totales se dibuja en la tabla
                            if (parametros.ordenadoPor == "cantidad") { arrayProductosFinal.sort(((a, b) => b.cantidad - a.cantidad)); }
                            if (parametros.ordenadoPor == "valor") { arrayProductosFinal.sort(((a, b) => b.valor - a.valor)); }


                            //se ordena el objeto de mayor a menor por la cantidad de cajas
                            //para que en la grafica quede ordenado, la grafica quedamos en siempre mostraba cantidades
                            const objOrdenadoPorCantidad = Object.entries(objResultado).sort(([, a], [, b]) => b - a)
                                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

                            context.commit('setProductosMasVendidosPorGrupoClientes', objOrdenadoPorCantidad);
                            context.commit('setProductosMasVendidosPorGrupoClientesConPorcentaje', arrayProductosFinal);
                            context.commit('setTotalesProductosMasVendidosPorGrupoClientes', { totalCajas: totalCajas, totalValor: totalValor });

                        } else {
                            Swal.fire("No hay ventas para ese grupo en las fechas seleccionadas", "", "error")
                        }
                    }).catch((err) => { console.log(err.message); });




                }
                else { Swal.fire("No existe el grupo a consultar", "", "error") }
            }
            catch (e) {
                console.error("Error leyendo documentos: ", e);
            }
        },
        async participacionVentasDeClientesEnSuGrupo(context, parametros) {
            //console.log(parametros)   
            try {
                context.commit('setParticipacionVentasDeClientesEnSuGrupo', "ninguno");
                //traigo los clientes que pertenecen a ese grupo
                let docRef = doc(db, "grupos", parametros.idGrupo);
                let docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    //integrantes es un array => ["OLIMPICA BOGOTA","OLIMPICA BARRANQUILLA"]
                    let integrantes = docSnap.data().integrantes;

                    const ventasRef = collection(db, "ventas");
                    const q = query(ventasRef, where('cliente', 'in', integrantes),
                        where("fecha", ">=", parametros.fechaDesde),
                        where("fecha", "<=", parametros.fechaHasta),
                    );

                    getDocs(q).then((snapshot) => {
                        if (snapshot.size > 0) {
                            let arrayClientesFinal = [];
                            let validacion;
                            let totalVentaCliente;
                            let totalValor = 0;

                            snapshot.docs.forEach((venta, index) => {
                                validacion = false;
                                totalVentaCliente = 0;

                                //console.log(venta.data());                              
                                //console.log("venta:"+venta.data().id,venta.data().productos);  
                                venta.data().productos.forEach((productoIterado) => {
                                    //console.log(productoIterado)
                                    totalValor += productoIterado.cantidad * productoIterado.valor
                                    //console.log(totalValor)
                                    totalVentaCliente += productoIterado.cantidad * productoIterado.valor
                                });

                                console.log("Venta " + venta.data().id + ", cliente index " + index + "; " + venta.data().cliente + " => " + totalVentaCliente)



                                arrayClientesFinal.forEach((clienteEnArrayClientesFinal) => {
                                    if (venta.data().cliente == clienteEnArrayClientesFinal.nombre) {
                                        clienteEnArrayClientesFinal.valor += totalVentaCliente
                                        validacion = true;
                                    }
                                })

                                if (validacion == false) {
                                    //si el producto no estaba en totales entonces hay que agregarlo como viene
                                    arrayClientesFinal.push({
                                        nombre: venta.data().cliente,
                                        valor: totalVentaCliente
                                    })
                                }
                            })

                            console.log("totalValor =>" + totalValor)


                            //arrayClientesFinal es lo que enviaré a dibujar en la tabla, le saco el porcentaje
                            //de participacion con respecto a los demas clientes de ese grupo
                            let porcentaje;
                            //console.log("ordenados por cantidad")
                            arrayClientesFinal.forEach((cliente) => {
                                porcentaje = (cliente.valor * 100) / totalValor
                                cliente.porcentaje = porcentaje.toFixed(1)
                            })

                            console.log(arrayClientesFinal);

                            //ordeno valores de mayor a menor totales
                            arrayClientesFinal.sort(((a, b) => b.valor - a.valor));

                            context.commit('setParticipacionVentasDeClientesEnSuGrupo', arrayClientesFinal);

                        } else {
                            Swal.fire("No hay ventas para ese grupo en las fechas seleccionadas", "", "error")
                        }
                    }).catch((err) => { console.log(err.message); });
                }
                else { Swal.fire("No existe el grupo a consultar", "", "error") }
            }
            catch (e) {
                console.error("Error leyendo documentos: ", e);
            }
        },
        async consultarProductos(context) {
            let productos = [];
            const q = query(collection(db, "productos"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                productos.push({ ...doc.data(), id: doc.id })
            });
            //console.log("productos en actions:")
            //console.log(productos);
            context.commit('setProductos', productos);
        },
        async consultarPuntosVenta(context) {
            let puntosDeVenta = [];
            const q = query(collection(db, "puntosDeVenta"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                puntosDeVenta.push({ ...doc.data() })
            });
            //console.log(puntosDeVenta);
            context.commit('setPuntosDeVenta', puntosDeVenta);
        },
        async consultarArrayObjsDeDoc(context, datos) {
            console.log(datos)
            let ArrObjs = [];
            const docRef = doc(db, datos.coleccion, datos.idDoc);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                //console.log(docSnap.data())
                for(let i in docSnap.data()){
                    ArrObjs.push(docSnap.data()[i]);
                }
                //console.log("seteando el estado "+datos.estado+" con los datos",ArrObjs);
                context.commit('setEstado', {estado:datos.estado, datos:ArrObjs});
            } else {
                alert("error en Vuex, funcion consultarArrayObjsDeDoc(), no existe el documento "+datos.idDoc+" en la coleccion "+datos.coleccion);
            }
            
        },        
        async limpiarEstado(context, parametros) {
            //console.log(parametros)         
            context.commit('limpiarEstado', parametros);
        } 
    },
    getters: {
        obtenerGruposPuntosDeVenta(state) {
            //console.log(state.gruposPuntosDeVenta)
            return state.gruposPuntosDeVenta;
        },
        obtenerUsuarioLogueado(state) {
            return state.user;
        },
        obtenerProductosEnEstado(state) {
            return state.productos;
        },
        obtenerGrupos(state) {
            return state.grupos;
        },/*
        obtenerPermisosUsuarioDefecto (state) {
            return state.permisosUsuarioPorDefecto;
        }*/
    }/*, plugins:[new VuexPersistence({storage: window.localStorage}).plugin]*/
})
// export the store
export default storeaAutenticacion